<!--
 * @Author: your name
 * @Date: 2020-12-31 09:29:17
 * @LastEditTime: 2020-12-31 10:06:02
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/views/footerlink/support/Support.vue
-->
<template>
<item-wrapper>
    <item-header title="Support" />
    <main>
        <div class="wrapper">
            <router-link to="/support/iPad" class="wrapper-item">
                <img class="ipad" src="/images/support/iPad.png" alt=""><span>iPad</span>
            </router-link>
            <router-link to="/support/iPhone" class="wrapper-item">
                <img class="iphone" src="/images/support/iPhone.png" alt=""><span>iPhone</span>
            </router-link>
            <router-link to="/support/Watch" class="wrapper-item">
                <img class="watch" src="/images/support/watch.png" alt=""><span>Watch</span>
            </router-link>
        </div>
    </main>
</item-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Support',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    :deep(header) {
        h2{
            margin-bottom: 89px;
        }
    }
    main{
        @include flex-center();
        margin-bottom: 80px;
       .wrapper{
           @include flex-center();
           margin-right: -65px;
           .wrapper-item{
               @include flex-center();
               flex-direction: column;
               width: 383px;
               height: 422px;
               border-radius: 6px;
               border: 1px solid #969696;
               margin-right: 65px;
               &:hover{
                border: 1px solid $theme-color;
               }
               .ipad{
                   width: 335px;
                   height: 335px;
                   margin-bottom: 10px;
               }
               .iphone{
                    width: 298px;
                   height: 357px;
               }
               .watch{
                     width: 335px;
                   height: 335px;
               }
               span{
                   font-size: 18px;
                   font-weight: bold;
                   color: $font-color;
                   line-height: 21px;
               }
           }
       }
    }

</style>
