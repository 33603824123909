
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Support',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
